//jsxhook

import React, { useContext, ReactNode } from "react"
import { CountryWithCodeI, defaultSupportedCountries } from "./Region"

export const SupportedCountriesContext = React.createContext<
  CountryWithCodeI[]
>(defaultSupportedCountries)

export const SupportedCountriesProvider: React.FC<{
  children?: ReactNode
  countries?: CountryWithCodeI[]
}> = ({ countries, children }) => {
  return (
    <SupportedCountriesContext.Provider
      value={countries || defaultSupportedCountries}
    >
      {children}
    </SupportedCountriesContext.Provider>
  )
}

export const useSupportedCountries: () => CountryWithCodeI[] = () => {
  return useContext(SupportedCountriesContext)
}
