export const useAddressReferenceData = () => {
  return {
    usStates,
    canadianProvinces,
    icelandicPostcodes: validIcelandicPostcodes
  }
}

interface NorthAmericanRegion {
  name: string
  abbreviation: string
}

const canadianProvinces: NorthAmericanRegion[] = [
  {
    name: "Alberta",
    abbreviation: "AB"
  },
  {
    name: "British Columbia",
    abbreviation: "BC"
  },
  {
    name: "Manitoba",
    abbreviation: "MB"
  },
  {
    name: "New Brunswick",
    abbreviation: "NB"
  },
  {
    name: "Newfoundland and Labrador",
    abbreviation: "NL"
  },
  {
    name: "Nova Scotia",
    abbreviation: "NS"
  },
  {
    name: "Northwest Territories",
    abbreviation: "NT"
  },
  {
    name: "Nunavut",
    abbreviation: "NU"
  },
  {
    name: "Ontario",
    abbreviation: "ON"
  },
  {
    name: "Prince Edward Island",
    abbreviation: "PE"
  },
  {
    name: "Québec",
    abbreviation: "QC"
  },
  {
    name: "Saskatchewan",
    abbreviation: "SK"
  },
  {
    name: "Yukon",
    abbreviation: "YT"
  }
]

const usStates: NorthAmericanRegion[] = [
  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Alaska",
    abbreviation: "AK"
  },
  {
    name: "American Samoa",
    abbreviation: "AS"
  },
  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "Delaware",
    abbreviation: "DE"
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC"
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Guam",
    abbreviation: "GU"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH"
  },
  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },
  {
    name: "Palau",
    abbreviation: "PW"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI"
  },
  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  }
]

const validIcelandicPostcodes = [
  "101",
  "102",
  "103",
  "104",
  "105",
  "107",
  "108",
  "109",
  "110",
  "111",
  "112",
  "113",
  "116",
  "121",
  "123",
  "124",
  "125",
  "127",
  "128",
  "129",
  "130",
  "132",
  "161",
  "162",
  "170",
  "172",
  "190",
  "191",
  "200",
  "201",
  "202",
  "203",
  "206",
  "210",
  "212",
  "220",
  "221",
  "222",
  "225",
  "230",
  "232",
  "233",
  "235",
  "240",
  "241",
  "245",
  "246",
  "250",
  "251",
  "260",
  "262",
  "270",
  "271",
  "276",
  "300",
  "301",
  "302",
  "310",
  "311",
  "320",
  "340",
  "341",
  "342",
  "345",
  "350",
  "351",
  "355",
  "356",
  "360",
  "370",
  "371",
  "380",
  "381",
  "400",
  "401",
  "410",
  "415",
  "416",
  "420",
  "421",
  "425",
  "426",
  "430",
  "431",
  "450",
  "451",
  "460",
  "461",
  "465",
  "466",
  "470",
  "471",
  "500",
  "510",
  "511",
  "512",
  "520",
  "524",
  "530",
  "531",
  "540",
  "541",
  "545",
  "546",
  "550",
  "551",
  "560",
  "561",
  "565",
  "566",
  "570",
  "580",
  "581",
  "600",
  "601",
  "602",
  "603",
  "604",
  "605",
  "606",
  "607",
  "610",
  "616",
  "611",
  "620",
  "621",
  "625",
  "626",
  "630",
  "640",
  "641",
  "645",
  "650",
  "660",
  "670",
  "671",
  "675",
  "676",
  "680",
  "681",
  "685",
  "686",
  "690",
  "691",
  "700",
  "701",
  "710",
  "711",
  "715",
  "720",
  "721",
  "730",
  "731",
  "735",
  "736",
  "740",
  "741",
  "750",
  "751",
  "755",
  "756",
  "760",
  "761",
  "765",
  "766",
  "780",
  "781",
  "785",
  "800",
  "801",
  "802",
  "803",
  "804",
  "805",
  "806",
  "810",
  "815",
  "816",
  "820",
  "825",
  "840",
  "845",
  "846",
  "850",
  "851",
  "860",
  "861",
  "870",
  "871",
  "880",
  "881",
  "900",
  "902"
]
